<template>
<div class="competition-header">
<div class="competition-header-image"></div>
  <div class="md:ml-16 md:mr-16 md:-mt-16 white-container md:p-12 p-8 md:mb-16 md:m-12 m-6 bg-white rounded-xl ">
    <div class="header-title flex md:items-center md:flex-row flex-col">
      <div class="flex-grow text-black text-2xl  text-left">{{ competition.title }}</div>
      <div class="flex-one md:mt-0 mt-6">
        <span class="text-black organised-by text-xl">{{ $t('organisedBy') }}</span>
        <span class="text-black text-xl leading-relaxed"> {{ competition.owner }}</span>
      </div>
    </div>
    <div class="h-6"></div>
    <div class="event-description flex md:flex-row flex-col">
      <div class="md:w-3/4 w-full text-black font-light text-justify text-xl event-description-data leading-relaxed">
        {{ competition.eventDescription }}
      </div>
      <div class="w-12"></div>
      <div class="md:flex-grow  cat-section md:mt-0 mt-6">
        <h3 class="text-xl text-black text-normal grey-color">{{ $t('cat') }}</h3>
        <div class="h-4"></div>
        <div class="flex flex-wrap">
          <div v-for="(item, index) in competition.categories" :key="item + index" class="m-2">
            <Chips :label="item"></Chips>
          </div>
        </div>
        <div class="h-6"></div>
        <h3 class="text-xl md:mt-0 mt-6 text-black text-normal grey-color">{{ $t('shareChallenge') }}</h3>
        <div class="flex">
          <div class="social-icon cursor-pointer  m-3">
            <svg-icon name="ic_twitter_share" class="w-10 h-10" original></svg-icon>
          </div>
          <div class="social-icon cursor-pointer  m-3">
            <svg-icon name="ic_insta_share" class="w-10 h-10" original></svg-icon>
          </div>
          <div class="social-icon cursor-pointer  m-3">
            <svg-icon name="ic_facebook_share" class="w-10 h-10" original></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Chips from '../helpers/Chip'
export default {
  name: 'CompetitionHeader',
  components: { Chips },
  data () {
    return {
      competition: {
        title: 'Femme et santé',
        owner: 'Innov Agence',
        eventDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae turpis massa sed elementum. Sit amet tellus cras adipiscing enim eu. Vel facilisis volutpat est velit egestas dui id ornare arcu. Penatibus et magnis dis parturient. Etiam non quam lacus suspendisse faucibus interdum posuere. Laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Integer feugiat scelerisque varius morbi. Purus non enim praesent elementum facilisis. Quis vel eros donec ac. Amet mattis vulputate enim nulla aliquet porttitor lacus. Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Nam at lectus urna duis convallis convallis tellus. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae proin.',
        categories: ['Culture', 'Divertissement', 'Mode et beauté', 'People']
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.competition-header {
.competition-header-image {
  height: 35rem;
  background-color: $primaryBlue;
  background-image: url(../../assets/images/webp/img_fake_background.webp);
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}
  .organised-by  {
    color: $greyLight;
  }
  .event-description-data  {
    color: $greyLight;
  }
}
.grey-color {
  color: $greyLight;
}
@media only screen and (max-width: 600px) {
  .white-container {
    margin-top: -15rem;
  }
  .event-description-data {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
  }
}
</style>
