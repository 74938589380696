<template>
<div class="candidate">
  <div class="candidate-item-image rounded-xl bg-black"
       :style="bannerBgImage(candidate.photoUrl)">
  </div>
  <div class="h-6"></div>
  <div class="username flex items-center">
    <div class="">
      <h3 class="text-2xl text-black text-left font-normal">N°{{ number }} :</h3>
      <div class="h-1"></div>
      <h3 class="text-2xl text-black text-left font-normal">0{{ number }}</h3>
    </div>
    <div class="w-4"></div>
    <div class="flex-grow">
      <h3 class="text-3xl font-bold text-black">{{  candidate.name }}</h3>
      <h3 class="text-lg font-light votes-section mt-1">{{  candidate.votes }} {{ $t('votes') }}</h3>
    </div>
  </div>
  <div class="h-6"></div>
  <div class="flex">
    <div class="flex-grow">
      <VotingButton></VotingButton>
    </div>
    <div class="w-6"></div>
    <div class="w-16 h-16 rounded-full cursor-pointer flex items-center justify-center share-social">
      <ShareNetwork
        network="facebook"
        :url="candidate.photoUrl"
        title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
        description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
        quote="The hot reload is so fast it\'s near instant. - Evan You"
        hashtags="vuejs,vite"
      >
        <svg-icon name="ic_share" class="h-8 w-8"  original/>
      </ShareNetwork>
    </div>
  </div>
</div>
</template>

<script>
import VotingButton from '../helpers/VotingButton'
export default {
  name: 'Candidate',
  components: { VotingButton },
  props: {
    candidate: {
      type: Object,
      default: function () {
        return {
          photoUrl: 'https://firebasestorage.googleapis.com/v0/b/votetome-d8ab2.appspot.com/o/candidates%2FRectangle%20164%20(3).png?alt=media&token=1a4e38b3-ddc8-4fa6-8ac6-90a17aa80538',
          name: 'Fanta Diallo',
          votes: 300
        }
      }
    },
    number: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
    }
  },
  methods: {
    bannerBgImage (image) {
      return 'background-image: url("' + image + '")'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";
.candidate-item-image {
  height: 30rem;
  background-color: $primaryBlue;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
}
.votes-section  {
  color: $greyLight;
}
.share-social {
  background-color: rgba(96, 96, 96, .1);
}
</style>
