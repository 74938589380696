<template>
<div class="chip-component">
  <div class="chip h-10 rounded-full grey-color flex justify-center items-center font-semibold text-xl border p-6 border-black">
    {{  label }}
  </div>
</div>
</template>

<script>
export default {
  name: 'Chips',
  props: {
    label: {
      type: String,
      default: 'Chips'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.grey-color {
  color: $greyLight;
}
</style>
