<template>
<div class="candidates-section md:ml-16 md:mr-16 md:-mt-16 -mt-6 md:mb-16 md:m-12 m-6">
  <h3 class="text-2xl text-black font-bold">
    {{ $t('candidat') }}
  </h3>
  <div class="candidates-list-section flex flex-wrap md:flex-row flex-col">
    <div class="md:w-1/3 w-full md:p-6 md:mt-0 mt-6 mb-6 md:mb-0" v-for="(item, index) in competition.candidates" :key="item+index">
      <Candidate :candidate="item"></Candidate>
    </div>
  </div>
</div>
</template>

<script>
import Candidate from './Candidate'
export default {
  name: 'CandidatsSections',
  components: { Candidate },
  props: {
    competition: {
      type: Object,
      default: function () {
        return {
          title: 'Femme et santé',
          owner: 'Innov Agence',
          eventDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae turpis massa sed elementum. Sit amet tellus cras adipiscing enim eu. Vel facilisis volutpat est velit egestas dui id ornare arcu. Penatibus et magnis dis parturient. Etiam non quam lacus suspendisse faucibus interdum posuere. Laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt. Integer feugiat scelerisque varius morbi. Purus non enim praesent elementum facilisis. Quis vel eros donec ac. Amet mattis vulputate enim nulla aliquet porttitor lacus. Porttitor rhoncus dolor purus non enim praesent elementum facilisis leo. Nam at lectus urna duis convallis convallis tellus. Aenean euismod elementum nisi quis eleifend quam adipiscing vitae proin.',
          categories: ['Culture', 'Divertissement', 'Mode et beauté', 'People'],
          candidates: [
            {
              photoUrl: 'https://firebasestorage.googleapis.com/v0/b/votetome-d8ab2.appspot.com/o/candidates%2FRectangle%20164%20(3).png?alt=media&token=1a4e38b3-ddc8-4fa6-8ac6-90a17aa80538',
              name: 'Fanta Diallo',
              votes: 300
            }, {
              photoUrl: 'https://firebasestorage.googleapis.com/v0/b/votetome-d8ab2.appspot.com/o/candidates%2FRectangle%20164%20(4).png?alt=media&token=3f2f2d2b-1741-4052-81d1-a58a69bfa48d',
              name: 'Fanta Diallo',
              votes: 200
            },
            {
              photoUrl: 'https://firebasestorage.googleapis.com/v0/b/votetome-d8ab2.appspot.com/o/candidates%2FRectangle%20164%20(5).png?alt=media&token=66929b23-bdfe-410b-8127-3cddcdf6f0ef',
              name: 'Fanta Diallo',
              votes: 100
            },
            {
              photoUrl: 'https://firebasestorage.googleapis.com/v0/b/votetome-d8ab2.appspot.com/o/candidates%2FRectangle%20164%20(6).png?alt=media&token=ebf49576-ac2d-4ef2-adf0-1cbe55b8228d',
              name: 'Fanta Diallo',
              votes: 50
            },
            {
              photoUrl: 'https://firebasestorage.googleapis.com/v0/b/votetome-d8ab2.appspot.com/o/candidates%2FRectangle%20164%20(7).png?alt=media&token=110b8b3f-167f-4c45-b47d-e6793d2d6b19',
              name: 'Fanta Diallo',
              votes: 25
            },
            {
              photoUrl: 'https://firebasestorage.googleapis.com/v0/b/votetome-d8ab2.appspot.com/o/candidates%2FRectangle%20164%20(8).png?alt=media&token=88ba787c-70e6-4be3-804a-c282c4e1707d',
              name: 'Fanta Diallo',
              votes: 15
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss"  scoped>
@import "../../assets/style/sass/variables";
</style>
