<template>
<div class="voting-button h-16 flex text-white cursor-pointer text-2xl font-bold justify-center items-center rounded-full">
  {{  $t('vot') }}
</div>
</template>

<script>
export default {
  name: 'VotingButton'
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";
.voting-button  {
  background-color: $primaryBlue;
}
</style>
