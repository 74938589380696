<template>
<div class="competition-page">
  <div class="header">
    <Header></Header>
  </div>
  <div class="entreprise-image">
    <CompetitionHeader></CompetitionHeader>
  </div>
  <div class="candidats-section">
    <CandidatsSections></CandidatsSections>
  </div>
  <div class="footer">
    <Footer></Footer>
  </div>
</div>
</template>

<script>
import Header from '../../components/helpers/Header'
import CompetitionHeader from '../../components/competition/CompetitionHeader'
import CandidatsSections from '../../components/competition/CandidatsSections'
import Footer from '../../components/helpers/Footer'
export default {
  name: 'home',
  components: { Footer, CandidatsSections, CompetitionHeader, Header },
  data () {
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

</style>
